

















import Vue from 'vue';
import { ITherapyContent, ITherapyContentCelebration, ITherapyContentGroup, ITherapyContentRedirection, TherapyTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementView from '@/views/products/components/element-view.vue';
import errorContainer from '@/views/components/utilities/error-container.vue';

export default Vue.extend({
  name: 'product-player',
  components: {
    ElementView,
    errorContainer,
  },
  props: {
    uen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      therapyGroup: null as ITherapyContentGroup | null,
    };
  },
  computed: {
    loadingView(): boolean {
      return this.$store.state.loadingView;
    },
    hasTherapyGroup(): boolean {
      return !!this.therapyGroup?.group?.length;
    },
    storeTherapyGroup(): ITherapyContentGroup {
      return this.$store.getters['therapy/therapyContentGroup'];
    },
  },
  created() {
    if (!this.storeTherapyGroup?.group?.some(tc => tc.uen === this.uen)) {
      this.getTherapyContent(this.uen).then(() => {
        this.therapyGroup = this.storeTherapyGroup;
        if (!this.uen) {
          this.redirectToUen(this.storeTherapyGroup.group[0].uen);
        }
      });
    } else {
      this.therapyGroup = this.storeTherapyGroup;
      if (!this.uen) {
        this.redirectToUen(this.storeTherapyGroup.group[0].uen);
      }
      this.$store.commit('navigation/topHeader', this.therapyGroup.group[0].title);
    }
  },
  methods: {
    async getTherapyContent(uen?: string, pickup?: boolean) {
      this.$log.debug('Loading therapy for uen ', uen);
      this.$store.commit('setLoadingView', true);
      await this.$store.dispatch('therapy/getTherapyContent', { uen, pickup }).then(res => {
        if (res.status >= 400) {
          if (this.$route.name === 'videos-run') {
            // fallback in case user doesn't have access to video map
            this.$router.replace({ name: 'video-library' });
            return;
          }
          this.redirectToPath('home');
          return;
        }
        const therapyGroup: ITherapyContentGroup = res;
        this.$log.debug('Loaded therapy', therapyGroup);
        if (therapyGroup?.group) {
          const redirection: ITherapyContentRedirection = therapyGroup.group.find(tc => tc.type === TherapyTypeEnum.REDIRECTION) as ITherapyContentRedirection;
          if (redirection) {
            const celebration: ITherapyContentCelebration = therapyGroup.group.find(tc => tc.type === TherapyTypeEnum.CELEBRATION) as ITherapyContentCelebration;
            if (celebration) {
              this.$store.commit('therapy/setCelebrationPopup', celebration);
            }
            this.redirectToPath(redirection.redirection);
            return;
          }
          this.$store.commit('navigation/topHeader', therapyGroup.group[0].title);
        }
        this.$store.commit('setLoadingView', false);
      });
    },
    completeGroup(payload?: { therapyContent?: ITherapyContent, pickup?: boolean }): void {
      this.$store.commit('setLoadingView', true);
      this.$store.dispatch('therapy/saveTherapy', this.therapyGroup).then(() => {
        if (payload?.therapyContent?.type === TherapyTypeEnum.REDIRECTION) {
          this.redirectToPath((payload.therapyContent as ITherapyContentRedirection).redirection);
          return;
        }
        this.getTherapyContent(payload?.therapyContent?.uen, payload?.pickup)
          .then(() => {
            if (this.storeTherapyGroup?.group?.[0]?.uen != this.therapyGroup?.group?.[0]?.uen) {
              const redirection: ITherapyContentRedirection = this.storeTherapyGroup.group.find(tc => tc.type === TherapyTypeEnum.REDIRECTION) as ITherapyContentRedirection;
              if (redirection) {
                this.redirectToPath(redirection.redirection);
              } else {
                this.redirectToUen(this.storeTherapyGroup.group[0].uen);
              }
            } else {
              this.$log.debug('Next action returned same route', this.therapyGroup?.group?.[0]?.uen);
              this.$store.commit('setLoadingView', false);
            }
          });
      });
    },
    redirectToUen(uen: string) {
      const routeName = this.$router.currentRoute.name?.includes('-menu') ? this.$router.currentRoute.name?.replace('-menu', '-run') : this.$router.currentRoute.name;
      this.$router.push({
        name: routeName as string,
        params: { uen },
      });
    },
    redirectToPath(path: string) {
      this.$router.push({ name: path.toLowerCase() }).catch(() => {});
    },
  },
});
